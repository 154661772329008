// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-benefiz-open-index-js": () => import("./../../../src/pages/benefiz-open/index.js" /* webpackChunkName: "component---src-pages-benefiz-open-index-js" */),
  "component---src-pages-datenschutz-index-js": () => import("./../../../src/pages/datenschutz/index.js" /* webpackChunkName: "component---src-pages-datenschutz-index-js" */),
  "component---src-pages-einfach-machen-index-js": () => import("./../../../src/pages/einfach-machen/index.js" /* webpackChunkName: "component---src-pages-einfach-machen-index-js" */),
  "component---src-pages-impressum-index-js": () => import("./../../../src/pages/impressum/index.js" /* webpackChunkName: "component---src-pages-impressum-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-karriere-index-js": () => import("./../../../src/pages/karriere/index.js" /* webpackChunkName: "component---src-pages-karriere-index-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-leistungen-index-js": () => import("./../../../src/pages/leistungen/index.js" /* webpackChunkName: "component---src-pages-leistungen-index-js" */),
  "component---src-pages-leistungen-onta-index-js": () => import("./../../../src/pages/leistungen/onta/index.js" /* webpackChunkName: "component---src-pages-leistungen-onta-index-js" */),
  "component---src-pages-ueber-uns-index-js": () => import("./../../../src/pages/ueber-uns/index.js" /* webpackChunkName: "component---src-pages-ueber-uns-index-js" */)
}

